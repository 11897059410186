import React, { Component } from 'react'
import Service from '../Networking/Networkingutils'
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import moment from "moment";
import { withSnackbar } from 'notistack';
import { getCookie } from '../Helper/CookieHelper'
import errorMessage from '../Helper/ErrorMessage'
import { CircleToBlockLoading } from 'react-loadingg';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import CalenderMini from './CalenderMini';
import DialogTitle from '@material-ui/core/DialogTitle';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TableMini from './TableMini';
import TableChartIcon from '@material-ui/icons/TableChart';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import BankHolidayTimesheet from './BankHolidayTimesheet/BankHolidayTimesheet';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    media: {
        height: 220,
        display: 'flex',
    },
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const MuiDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const token = getCookie("bb_ts_token");


class LMSDetailView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data1: [],
            userData: null,
            openDialogue: false,
            isLoading: true,
            isButtonDisabled1: false,
            calender: false,
            table: false,
            alerts: false,
            events: [],
            isProgress: true,
            checkLead: false,
            userinfo: null,
            annual: 0,
            casual: 0,
            text: '',
            status: [0],
            selectAllBtnName: 'Select all unapproved',
            btnDisabled: true,
            clickAction: false,
            bankHolidayOpen: false,
        }
        this.dataReceive = this.dataReceive.bind(this);
    }
    dataReceive(data) {
        this.setState({
            userinfo: data[0]
        })
    }
    componentDidMount() {
        this.fetchData();
        if (this.props.check_lead === true) {
            this.fetchID()
        } else {
            this.setState({ isProgress: false, checkLead: true })
        }
    }

    confirmApprove() {
        var leaves = this.state.userinfo ? this.state.userinfo : this.props.leaveinfo2 ? this.props.leaveinfo2 : null
        var sick_balance = parseInt((leaves.available_sick - leaves.sick_days).toFixed(2))
        var annual_balance = parseInt(((leaves.available_leave + leaves.annaul_carry_forward) - leaves.leave_days).toFixed(2))
        var selected_sick = this.state.casual
        var selecetd_annual = this.state.annual
        var data = this.state.data1
        var sick_array = []
        var annual_array = []

        for (let i = 0; i < data.length; i++) {
            const ele = data[i]
            if (ele.approval === 0 && ele.team_task_data.tname === 'Casual / Sick Leave') {
                sick_array.push(ele)
            }
            else if (ele.approval === 0 && ele.team_task_data.tname === 'Annual Leave') {
                annual_array.push(ele)
            }
        }

        var Sick_check = sick_array.length + sick_balance - selected_sick
        var Annual_check = annual_array.length + annual_balance - selecetd_annual
        if (this.state.casual !== 0 && this.state.annual !== 0) {
            if (Sick_check >= 0 && Annual_check >= 0) {
                this.approval()
            } else if (Sick_check >= 0 && Annual_check < 0) {
                this.setState({ alerts: true, text: 'Selected Hours are exceeded than available in Annual' })
            } else if (Sick_check < 0 && Annual_check >= 0) {
                this.setState({ alerts: true, text: 'Selected Hours are exceeded than available in Casual / Sick' })
            } else if (Sick_check <= 0 && Annual_check <= 0) {
                this.setState({ alerts: true, text: 'Selected Hours are exceeded than available in both Casual / Sick & Annual' })
            }
        } else if (this.state.casual !== 0 && this.state.annual === 0) {
            if (Sick_check >= 0) {
                this.approval()
            } else {
                this.setState({ alerts: true, text: 'Selected Hours are exceeded than available in Casual / Sick' })
            }
        } else if (this.state.casual === 0 && this.state.annual !== 0) {
            if (Annual_check >= 0) {
                this.approval()
            } else {
                this.setState({ alerts: true, text: 'Selected Hours are exceeded than available in Annual' })
            }
        } else {
            this.approval()
        }
    }

    isReject(date) {
        var date1 = moment();
        var date2 = moment(date).utc().startOf('date').add(15, 'days');
        return date1.isAfter(date2);
    }

    screenSizeCheck() {
        if (window.innerWidth > 1600) {
            return true
        } else {
            return false
        }
    }

    sendProps() {
        if (this.props.match) {
            return this.props.match.params.id
        } else if (this.props.user_id) {
            return this.props.user_id
        } else if (this.props.value) {
            return this.props.value
        }
    }

    datasCheck(value) {
        if (value.team_task_data && value.snapshot) {
            if ((moment(value.team_task_data.end).diff(moment(value.team_task_data.start), 'hours')) !== (moment(value.snapshot.end).diff(moment(value.snapshot.start), 'hours')) && (moment(value.team_task_data.start).format('DD-MM-YYYY')) !== (moment(value.snapshot.start).format('DD-MM-YYYY'))) {
                return <Box display="flex" justifyContent="center" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                    <Alert
                        severity="info"
                        action={
                            <Button color="inherit" size="small" onClick={e => { this.setState({ userData: value, openDialogue: true }) }}>
                                View Change
                            </Button>
                        }
                    >{"Changed to " + (moment(value.team_task_data.end).diff(moment(value.team_task_data.start), 'hours')) + " day on " + (moment(value.team_task_data.start).format('DD-MM-YYYY'))}</Alert>
                </Box>

            } else if ((moment(value.team_task_data.start).format('DD-MM-YYYY')) !== (moment(value.snapshot.start).format('DD-MM-YYYY'))) {
                return <Box display="flex" justifyContent="center" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                    <Alert
                        severity="info"
                        action={
                            <Button color="inherit" size="small" onClick={e => { this.setState({ userData: value, openDialogue: true }) }}>
                                View Change
                            </Button>
                        }
                    >{"Moved to " + (moment(value.team_task_data.start).format('DD-MM-YYYY'))}</Alert>
                </Box>
            } else if ((moment(value.team_task_data.end).diff(moment(value.team_task_data.start), 'hours')) !== (moment(value.snapshot.end).diff(moment(value.snapshot.start), 'hours'))) {
                return <Box display="flex" justifyContent="center" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                    <Alert
                        severity="info"
                        action={
                            <Button color="inherit" size="small" onClick={e => { this.setState({ userData: value, openDialogue: true }) }}>
                                View Change
                            </Button>
                        }
                    >{"Changed to " + (moment(value.team_task_data.end).diff(moment(value.team_task_data.start), 'hours')) + " hours"}</Alert>
                </Box>
            }
        } else {
            return null
        }

    }

    fetchData() {
        this.setState({ isLoading: true })
        var url = null
        if (this.props.match && this.props.match.params.id) {
            url = `/bb/api/lms/approval/lms_leave_details/?user=${this.props.match.params.id}&status=${this.state.status}`
        } else if (this.props.lead_id) {
            url = `/bb/api/lms/approval/lms_leave_details/?user=${this.props.user_id}&requested_to=${this.props.lead_id}&status=${this.state.status}`
        } else if (this.props.value) {
            url = `/bb/api/lms/approval/lms_leave_details/?user=${this.props.value}&status=${this.state.status.join(",")}`
        }
        this.setState({ btnDisabled: true, selectAllBtnName: 'Select all unapproved' })
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let j = 0; j < res.data.length; j++) {
                    const element = res.data[j];
                    element.isLoading = false;
                    element.isButtonDisabled = false;
                    element.isButtonDisabled1 = false;
                    element.isSelected = false;
                }

                this.setState({
                    data1: res.data,
                    isLoading: false,
                }, () => {
                    for (let i = 0; i < this.state.data1.length; i++) {
                        const element = this.state.data1[i];
                        if (element.approval === 0) {
                            this.setState({ btnDisabled: false, selectAllBtnName: 'Select all unapproved' })
                            break
                        }
                    }
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            })
    }

    approval = (event, i) => {
        var cards = this.state.data1
        var card_array = [];

        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].approval === 0) {
                    card_array.push(cards[i].id)
                    cards[i].isLoading = true;
                    cards[i].isSelected = true;
                    cards[i].isButtonDisabled = false;
                }
            }
        }
        this.setState({ data1: cards, alerts: false })

        Service.patch("/bb/api/lms/approval/receiver_approval/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: card_array,
                is_approved: 1,
            },
        })
            .then(res => {
                for (let i = 0; i < cards.length; i++) {
                    if (cards[i].isSelected === true) {
                        if (cards[i].approval === 0) {
                            cards[i].isLoading = false;
                            cards[i].isSelected = false;
                            cards[i].isButtonDisabled = true;
                        }
                    }
                }

                this.setState({ data1: cards })
                if (this.props.action) {
                    this.props.action(true)
                }
                this.props.enqueueSnackbar('Approved Successfully', {
                    variant: 'success'
                });;
                this.fetchData();
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    reject = (event, i) => {
        var cards = this.state.data1
        var card_array = [];
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].approval === 1 || cards[i].approval === 0) {
                    card_array.push(cards[i].id)
                    cards[i].isLoading = true;
                    cards[i].isButtonDisabled = false;
                }
            }
        }

        this.setState({ data1: cards })

        Service.patch("/bb/api/lms/approval/receiver_approval/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                id: card_array,
                is_approved: 2,
            },
        })
            .then(res => {
                for (let i = 0; i < cards.length; i++) {
                    if (cards[i].isSelected === true) {
                        if (cards[i].approval === 1 || cards[i].approval === 0) {
                            cards[i].isLoading = false;
                            cards[i].isSelected = false;
                            cards[i].isButtonDisabled = true;
                        }
                    }
                }

                this.setState({ data1: cards })
                if (this.props.action) {
                    this.props.action(true)
                }
                this.props.enqueueSnackbar('Rejected Successfully', {
                    variant: 'success'
                });
                this.fetchData();
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });

    }

    handleSelect(e, i) {
        var datas = this.state.data1;
        datas[i].isSelected = true;
        if (datas[i].approval === 1) {
            this.setState({ btnDisabled: true })
        }
        this.setState({ data1: datas }, () => {
            let approved = []
            let selected = []
            for (let j = 0; j < this.state.data1.length; j++) {
                const element = this.state.data1[j];
                if (element.approval === 0) {
                    approved.push(element)
                }
                if (element.isSelected && element.approval !== 1) {
                    selected.push(element)
                }
            }
            if ((approved.length === selected.length) && (this.state.selectAllBtnName === 'Select all unapproved')) {
                this.setState({ selectAllBtnName: 'Unselect all unapproved' })
            }
        })
    }

    handleUnSelect(e, i) {
        var datas = this.state.data1;
        datas[i].isSelected = false;
        if (datas[i].approval === 1) {
            this.setState({ btnDisabled: true })
        }
        this.setState({ data1: datas }, () => {
            // for (let j = 0; j < this.state.data1.length; j++) {
            //     const element = this.state.data1[j];
            //     if (element.approval === 0 && element.isSelected && this.state.selectAllBtnName === 'Unselect all unapprove') {
            //         this.setState({btnDisabled: true, selectAllBtnName: 'Select all unapprove' })
            //         break
            //     }
            // }
            for (let j = 0; j < this.state.data1.length; j++) {
                const element = this.state.data1[j];
                if (element.approval === 1 && element.isSelected) {
                    this.setState({ btnDisabled: true, selectAllBtnName: 'Select all unapproved' })
                    break
                } else if (element.approval === 0) {
                    this.setState({ btnDisabled: false, selectAllBtnName: 'Select all unapproved' })
                    // break
                }
            }
            // for (let j = 0; j < this.state.data1.length; j++) {
            //     const element = this.state.data1[j];
            //     if (element.approval === 0) {
            //         this.setState({ btnDisabled: false, selectAllBtnName: 'Select all unapprove' })
            //         break
            //     }
            // }
        })
    }

    selectAllUnApproved(value) {
        let request_dates = this.state.data1
        for (let i = 0; i < request_dates.length; i++) {
            const element = request_dates[i];
            if (element.approval === 0) {
                element.isSelected = value
            }
        }
        this.setState({ data1: request_dates, selectAllBtnName: value ? 'Unselect all unapproved' : 'Select all unapproved' })
    }

    selectButton(value, i) {
        if (value.approval === 0) {
            return (
                <Button
                    disabled={this.enableSelect() || value.isButtonDisabled === true}
                    variant="contained"
                    color={value.isSelected ? "secondary" : "primary"}
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                    onClick={e => value.isSelected ? this.handleUnSelect(e, i) : this.handleSelect(e, i)}>
                    {value.isSelected ? "Unselect" : "Select"}
                </Button>
            )
        } else if (value.approval === 1) {
            return (
                <Box display="flex" justifyContent="center" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                    <Typography noWrap>
                        <Box display="flex" alignItems="center" >
                            <Box style={{ color: "green" }} marginTop="5px"><CheckCircleIcon /></Box>
                            <Box style={{ color: "green" }} marginBottom="1px" marginLeft="3px">Approved by {value.replied_by_data.first_name}</Box>
                        </Box>
                    </Typography>
                    {(this.isReject(value.team_task_data.start)) ? (null) : (
                        <Button
                            disabled={this.disableSelect() || value.isButtonDisabled === true}
                            variant="contained"
                            color={value.isSelected ? "secondary" : "primary"}
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                            onClick={e => value.isSelected ? this.handleUnSelect(e, i) : this.handleSelect(e, i)}>
                            {value.isSelected ? "Unselect" : "Select"}
                        </Button>
                    )}
                </Box>
            )
        } else if (value.approval === 2) {
            return <Box display="flex" justifyContent="center" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                <Typography noWrap>
                    <Box display="flex" alignItems="center" >
                        <Box style={{ color: "red" }} marginTop="5px"><CancelIcon /></Box>
                        <Box style={{ color: "red" }} marginBottom="1px" marginLeft="3px">Rejected by {value.replied_by_data.first_name}</Box>
                    </Box>
                </Typography>
            </Box>
        } else {
            return null
        }
    }

    disableSelect() {
        var cards = this.state.data1;
        var card_array = [];
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].approval === 0) {
                    card_array.push(cards[i])
                }
            }
            if (card_array.length !== 0) {
                return true
            }
        }
    }
    enableSelect() {
        var cards = this.state.data1;
        var card_array = [];
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].approval === 1) {
                    card_array.push(cards[i])
                }
            }
            if (card_array.length !== 0) {
                return true
            }
        }
    }
    enableApprove() {
        var cards = this.state.data1;
        var card_array = [];
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].approval === 0) {
                    card_array.push(cards[i]);
                }
            }
        }
        if (card_array.length === 0) {
            return true
        } else {
            return false
        }
    }
    enableReject() {
        var cards = this.state.data1;
        var card_array = [];
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].approval === 1) {
                    card_array.push(cards[i]);
                }
            }
        }
        if (card_array.length === 0) {
            return true
        } else {
            return false
        }
    }
    calenderView() {
        this.setState({ calender: true })
    }
    tableView() {
        this.setState({ table: true })
    }
    fetchID() {
        const user = JSON.parse(getCookie("bb_ts_user"));
        const lead_id = user.id;
        Service.get(`/bb/api/user/report/view/members_workload/?uid=${lead_id}`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ isProgress: false })
                var ids = []

                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (!ids.includes(element.uid_data.id)) {
                        ids.push(element.uid_data.id)
                    }
                }
                if (ids.includes(parseInt(this.props.user_id))) {
                    this.setState({ checkLead: true })
                } else {
                    this.setState({ checkLead: false })
                }
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)

            });
    }
    showHours() {
        var cards = this.state.data1;
        var casual_array = [];
        var annual_array = [];
        var casual_hours = 0
        var annual_hours = 0
        for (let i = 0; i < cards.length; i++) {
            if (cards[i].isSelected === true) {
                if (cards[i].team_task_data.tname === "Casual / Sick Leave") {
                    casual_array.push(cards[i]);
                    if (!casual_hours) {
                        casual_hours = cards[i].team_task_data.actual_hours
                    } else {
                        casual_hours = casual_hours + cards[i].team_task_data.actual_hours
                    }
                } else if (cards[i].team_task_data.tname === "Annual Leave") {
                    annual_array.push(cards[i]);
                    if (!annual_hours) {
                        annual_hours = cards[i].team_task_data.actual_hours
                    } else {
                        annual_hours = annual_hours + cards[i].team_task_data.actual_hours
                    }
                }
            }
        }
        if (this.state.casual !== casual_hours || this.state.annual !== annual_hours) {
            this.setState({
                casual: casual_hours,
                annual: annual_hours
            })
        }
        if (casual_array.length === 0 && annual_array.length === 0) {
            return null
        } else if (casual_array.length !== 0 && annual_array.length === 0) {
            return (<Typography variant="caption" display="block" gutterBottom>
                {"Selected " + casual_hours + " Hours in Casual / Sick"}
            </Typography>)
        } else if (casual_array.length === 0 && annual_array.length !== 0) {
            return (<Typography variant="caption" display="block" gutterBottom>
                {"Selected " + annual_hours + " Hours in Annual"}
            </Typography>)
        } else {
            return (<Typography variant="caption" display="block" gutterBottom>
                {"Selected " + casual_hours + " Hours in Casual / Sick & " + annual_hours + " Hours in Annual"}
            </Typography>)
        };
    }

    setStatus(value) {
        if (value.length === 0) {
            value = [0]
        }
        this.setState({
            status: value
        }, () => {
            this.fetchData()
        })
    }


    render() {

        const { classes } = this.props;

        const handleDialogueClose = () => {
            this.setState({ openDialogue: false, calender: false, table: false, alerts: false, bankHolidayOpen: false })
        };


        return (
            <div>
                {(this.state.isLoading) ? <CircleToBlockLoading color='#007681' /> : (
                    <div >
                        <Box display="flex" justifyContent="flex-end" alignItems={'center'} marginRight="20px">
                            <ToggleButtonGroup color="primary" size="small" value={this.state.status} onChange={(e, v) => this.setStatus(v)}>
                                <ToggleButton value={0} >
                                    Pending
                                </ToggleButton>
                                <ToggleButton value={1} >
                                    Approved
                                </ToggleButton>
                                <ToggleButton value={2} >
                                    Rejected
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <Box display="flex" justifyContent="center" padding="10px">
                                {this.showHours()}
                            </Box>
                            <Box display="flex" justifyContent="center" padding="10px">
                                <Button
                                    disabled={this.enableApprove()}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}
                                    // onClick={e => this.confirmApprove(e)}>
                                    onClick={e => this.approval(e)}>
                                    Approve
                                </Button>
                                {/* <Button
                                    // disabled={this.enableApprove()}-
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}
                                    // onClick={e => this.confirmApprove(e)}>
                                    onClick={e => {this.setState({clickAction: true}); this.props.action(true)}}>
                                    Check
                                </Button> */}
                                <Button
                                    disabled={this.enableApprove() && this.enableReject()}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: '0px', marginRight: '10px' }}
                                    onClick={e => this.reject(e)}>
                                    Reject
                                </Button>
                                <Button
                                    disabled={this.state.btnDisabled}
                                    variant="contained"
                                    color="primary"
                                    size='small'
                                    style={{ marginLeft: '0px', marginRight: '10px' }}
                                    onClick={e => this.selectAllUnApproved(this.state.selectAllBtnName === 'Select all unapproved' ? true : false)}>
                                    {this.state.selectAllBtnName}
                                </Button>
                            </Box>
                            {this.state.isProgress ? (<CircularProgress color="primary" />) : (
                                this.state.checkLead ? (
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size='small'
                                            style={{ marginLeft: '50px', marginRight: '10px' }}
                                            onClick={e => this.calenderView(e)}
                                            startIcon={<CalendarTodayIcon />}>
                                            Calendar View
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size='small'
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                            onClick={e => this.tableView(e)}
                                            startIcon={<TableChartIcon />}>
                                            Table View
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size='small'
                                            style={{ marginLeft: '10px', marginRight: '10px' }}
                                            onClick={e => this.setState({ bankHolidayOpen: true })}
                                            startIcon={<WorkOffIcon />}>
                                            Bank Holiday
                                        </Button>
                                    </div>) : (null)
                            )}
                        </Box>
                        {this.state.data1.length === 0 ? <Box marginTop="100px" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No {
                            this.state.status.includes(0) ? "Pending" : ""}
                            {this.state.status.includes(0) && this.state.status.includes(1) ? ", " : ""}
                            {this.state.status.includes(1) ? "Approved" : ""}
                            {(this.state.status.includes(0) || this.state.status.includes(1)) && this.state.status.includes(2) ? ", " : ""}
                            {this.state.status.includes(2) ? "Rejected" : ""}
                            {" "}Leaves to Show</Box> :

                            <Box marginTop="10px" flexDirection="row" display="flex" padding="15px">
                                <Grid container spacing={3} className={classes.root}  >
                                    {(this.state.data1).map((value, i) =>
                                        <Grid item xs={this.screenSizeCheck() ? 3 : 4} className={classes.root}>
                                            {/* <Paper > */}
                                            <Card elevation={2} className={classes.root} style={{ borderRadius: '20px', backgroundColor: !value.isSelected ? "white" : "#ccebff" }}>
                                                <CardContent>

                                                    <Typography gutterBottom >
                                                        Team : {(value.approval === 0) ? (value.team_task_data.gname) : (value.snapshot.gname)}
                                                    </Typography>
                                                    <Typography gutterBottom >
                                                        Leave type: {(value.approval === 0) ? (value.team_task_data.tname) : (value.snapshot.tname)}
                                                    </Typography>
                                                    <Typography gutterBottom >
                                                        {/* Hours : {(value.approval === 0) ? ((moment.duration(moment(value.team_task_data.end).diff(moment(value.team_task_data.start))).asMinutes() / 60).toFixed(2) ) : ((moment.duration(moment(value.snapshot.end).diff(moment(value.snapshot.start))).asMinutes() / 60).toFixed(2) )} */}
                                                        Duration: {value.duration_in_hours + " hours (" + value.duration_in_days + " days)"}
                                                    </Typography>
                                                    <Typography gutterBottom >
                                                        Date : {(value.approval === 0) ? (moment(value.team_task_data.start).format('DD-MM-YYYY')) : (moment(value.snapshot.start).format('DD-MM-YYYY'))}
                                                    </Typography>
                                                    <Typography gutterBottom >
                                                        Comment : {(value.approval === 0) ? (value.team_task_data.comments) : (value.snapshot.comments)}
                                                    </Typography>

                                                    {this.datasCheck(value)}

                                                </CardContent>

                                                {(value.isLoading === true) ? (<Box display="flex" justifyContent="center" ><CircularProgress /></Box>) :
                                                    (<Box display="flex" justifyContent="center" marginBottom="10px" bgcolor={!value.isSelected ? "white" : "#ccebff"}>
                                                        {this.selectButton(value, i)}
                                                    </Box>)}

                                            </Card>
                                            {/* </Paper> */}
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        }
                    </div>
                )}

                <Dialog open={this.state.openDialogue} onClose={handleDialogueClose} TransitionComponent={Transition} >
                    <DialogContent>
                        <Box marginTop="10px" flexDirection="row" display="flex" justifyContent="center" fontWeight="bold">
                            Changes
                        </Box>
                        <Box style={{ width: 250 }} marginTop="10px" display="flex" justifyContent="left" >
                            <b>Hours: </b> {(this.state.userData) ? (moment(this.state.userData.team_task_data.end).diff(moment(this.state.userData.team_task_data.start), 'hours') + " hours") : (null)}
                        </Box>
                        <Box style={{ width: 250 }} marginTop="10px" display="flex" justifyContent="left" >
                            <b>Date: </b> {(this.state.userData) ? (moment(this.state.userData.team_task_data.start).format('DD-MM-YYYY')) : (null)}
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                            <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={e => { this.setState({ openDialogue: false }) }}>Ok</Button>
                        </Box>
                    </DialogActions>
                </Dialog>

                <Dialog fullWidth='true' maxWidth='lg' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.calender} TransitionComponent={Transition}>
                    <MuiDialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                        Calendar
                    </MuiDialogTitle>
                    <DialogContent dividers>
                        <CalenderMini value={this.sendProps()} />
                    </DialogContent>
                </Dialog>

                <Dialog fullWidth='true' maxWidth='lg' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.table} TransitionComponent={Transition}>
                    <MuiDialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                        Table View
                    </MuiDialogTitle>
                    <DialogContent dividers>
                        <TableMini value={this.sendProps()} userinfo={this.state.userinfo ? this.state.userinfo.user_info : this.props.leaveinfo2 ? this.props.leaveinfo2.user_info : null} />                    </DialogContent>
                </Dialog>

                <Dialog fullWidth='true' maxWidth='lg' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.bankHolidayOpen} TransitionComponent={Transition}>
                    <MuiDialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                        Bank Holiday Utilization
                    </MuiDialogTitle>
                    <DialogContent dividers>
                        <BankHolidayTimesheet value={this.sendProps()} />                  </DialogContent>
                </Dialog>

                <Dialog fullWidth='true' maxWidth='sm' onClose={handleDialogueClose} aria-labelledby="customized-dialog-title" open={this.state.alerts} TransitionComponent={Transition}>
                    <MuiDialogTitle id="customized-dialog-title" onClose={handleDialogueClose}>
                        Alert
                    </MuiDialogTitle>
                    <DialogContent  >
                        <Box flexDirection="row" display="flex" justifyContent="center">
                            {this.state.text}
                        </Box>
                    </DialogContent>
                    <Box display="flex" justifyContent="center" marginTop="5px" marginBottom="10px">
                        <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={e => this.approval(e)}>Continue</Button>
                        <Button style={{ marginLeft: '5px', marginRight: '5px', color: "#007681" }} onClick={handleDialogueClose}>Edit</Button>
                    </Box>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(useStyles)(withSnackbar(LMSDetailView))